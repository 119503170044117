import { get } from 'lodash';
import { getDealerRooftopId } from 'site-modules/shared/utils/dealer';
import { handlePublicationState } from 'client/site-modules/shared/utils/publication-states';
import { getHandySlug } from 'site-modules/shared/utils/upper-funnel/type-mapping';

const REGEX_ZONE_SCRUB = /([\$\-\_\+\!\*\'\(\),\" ])/gi; // eslint-disable-line no-useless-escape
function scrubString(str) {
  return str.replace(REGEX_ZONE_SCRUB, '').toLowerCase();
}

export function pageValuesCheck(store, value, fallback) {
  if (store && store[value]) {
    return getHandySlug(`${store[value]}`);
  }
  return fallback || '';
}

/**
 * the wtf parameter is a part of targeting values, with a value populated
 * if the user was bucketed into an active WTF campaign
 * @returns {Object}
 */
function wtfContextGenerator(activeCampaigns) {
  const items = [];

  activeCampaigns.forEach(campaign => {
    if (campaign.campaignName.indexOf('_dfp') > -1 && campaign.forcedOrAssignedRecipeName) {
      items.push(`${campaign.campaignName}_${campaign.forcedOrAssignedRecipeName}`);
    }
  });

  return items;
}

/**
 * vehicle context specific to emdunds for ad from google (gpt)
 * it is necessary to use adTargetId value for ad targeting for mk,mdl,sub
 * @param {Object} vehicle page level vehicle object
 * @param {Object} options page level
 *
 * @returns {Object}
 */
export function getVehicleContext(vehicle, options) {
  const mk = scrubString(pageValuesCheck(vehicle.make, 'niceName'));
  const mdl = scrubString(pageValuesCheck(vehicle.model, 'niceName'));
  const sub = scrubString(pageValuesCheck(vehicle.subModel, 'adTargetId'));
  const year = pageValuesCheck(vehicle.modelYear, 'year', 'agnostic');
  const usein = handlePublicationState(get(vehicle, 'publicationState', 'new'));
  const type = options.adsSkipVehicleType ? '' : pageValuesCheck(vehicle.type, 'niceName');
  return {
    mk,
    mdl,
    year,
    sub,
    type,
    usein,
  };
}

/**
 * Generates a dealer specific context based on the current page for EAS Ads.
 * If the page is a dealer specific parameters will include `cddid` and `dlr=y` otherwise just `dlr=n`.
 * @param {Object} pageContext Page level values
 * @param {Object} dealer Dealer value on page level, mainly used as a fallback to get cddid.
 *
 * @returns {Object}
 */
function getDealerContext(pageContext, dealer) {
  const cddid = getDealerRooftopId(pageContext) || get(dealer, 'rooftopId');
  return cddid ? { cddid, dlr: 'y' } : { dlr: 'n' };
}

/**
 * page context specific to emdunds for ad from google (gpt)
 * @param {Object} page page level values
 * @param {String} page.name edw page name
 * @param {Object} page.category edw page category
 * @param {Object} location url params similar to window.location
 * @param {Object} vehicle page level vehicle object
 * @param {Array} activeCampaigns active campaigns on the current page
 *
 * @returns {Object}
 */
export function getPageContext(page, location, vehicle, legacy, dealer, headlessBrowser, frData, utmAds) {
  const timestamp = `${Date.now()}`;
  const fr = frData || '';
  const sect7 = headlessBrowser ? 'ivt' : '';
  let sect9 = '';
  let url = '';

  // eslint-disable-next-line no-underscore-dangle
  if (window !== undefined && window._edm_il === 1) {
    sect9 = 'ubk';
  }

  if (window !== undefined) {
    url = get(window, 'location.href');
  }

  return {
    edwpg: pageValuesCheck(legacy, 'pageName', pageValuesCheck(page, 'name')),
    edwcat: pageValuesCheck(legacy, 'categoryName', pageValuesCheck(page, 'category')),
    ts: pageValuesCheck(page, 'timestamp', timestamp),
    tsp: pageValuesCheck(page, 'timestamp', timestamp),
    ...getVehicleContext(vehicle, get(page, 'options', {})),
    ...getDealerContext({ page, location }, dealer),
    webapp: '',
    sect10: 'venom',
    sect7,
    sect9,
    fr,
    url,
    ...(utmAds || { utms: '' }),
    ...(page.adsCustomTargeting || {}),
  };
}

export function getWtfContext(activeCampaigns) {
  return {
    wtf: wtfContextGenerator(activeCampaigns),
  };
}
